<div class="container">
    <div class="row">
        <div class="col-md-12 text-center mt-5 mb-5">
            <img src="/assets/images/logo.png" class="logo" />
        </div>
    </div>
    <div class="row mt-5 mb-5">
        <div class="col">
            <input type="text" [(ngModel)]="usuario" placeholder="Usuario" class="form-control form-control-lg text-center" />
        </div>
    </div>
    <div class="row mt-5 mb-5">
        <div class="col">
            <input type="password" [(ngModel)]="password" placeholder="Contraseña" class="form-control form-control-lg text-center" />
        </div>
    </div>
    <div class="row mt-5 mb-5">
        <div class="col">
            <button class="btn btn-lg btn-warning w-100" (click)="ingresar()">Ingresar</button>
        </div>
    </div>
    <!--<div class="row">
        <div class="col-md-12">
            <select class="form-select form-select-lg mb-5" [(ngModel)]="usuario">
                <option value="">Seleccionar...</option>
                <ng-container *ngFor="let us of listaUsuarios">
                    <option [value]="us.usuario">{{ us.primer_nombre + ' ' + us.segundo_nombre + ' ' + us.primer_apellido + ' ' + us.segundo_apellido }}</option>
                </ng-container>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table_teclado">
                <tr>
                    <td (click)="presionaTecla('1')">1</td>
                    <td (click)="presionaTecla('2')">2</td>
                    <td (click)="presionaTecla('3')">3</td>
                </tr>
                <tr>
                    <td (click)="presionaTecla('4')">4</td>
                    <td (click)="presionaTecla('5')">5</td>
                    <td (click)="presionaTecla('6')">6</td>
                </tr>
                <tr>
                    <td (click)="presionaTecla('7')">7</td>
                    <td (click)="presionaTecla('8')">8</td>
                    <td (click)="presionaTecla('9')">9</td>
                </tr>
                <tr>
                    <td (click)="presionaTecla('0')">0</td>
                    <td (click)="borrar()">Borrar</td>
                    <td (click)="ingresar()">Ingresar</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center mt-5">
            <label class="text-warning display-4">{{ "*".repeat(password.length) }}</label>
        </div>
    </div>-->
</div>