<ng-container *ngIf="esPantallaPequena">
  <div class="row letra-color-lumen letra-robot mt-3" *ngIf="!estado">
    <div class="col-sm-12 text-center">
      <img src="/assets/images/logo.png" class="w-25 mb-2" />
      <h2>Liceo Lumen</h2>
      <hr/>
      <h1 class="mt-3">Sistema de Información Académico</h1>
    </div>
  </div>
  <div class="row menu-sm">
    <div class="col-sm-12">
      <div class="btn" [routerLink]="['/docentes']">
        <img src="/assets/images/docentes.png" class="w-25 align-middle" />
        <p class="letra-color-lumen letra-robot align-middle">Docentes</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/estudiantes']">
          <img src="/assets/images/estudiantes.png" class="w-25 align-middle" />
          <p class="letra-color-lumen letra-robot align-middle">Estudiantes</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/sprints']">
          <img src="/assets/images/actividades.png" class="w-25 align-middle" />
          <p class="letra-color-lumen letra-robot align-middle">Sprints</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/asistencia']">
          <img src="/assets/images/registro.png" class="w-25 align-middle" />
          <p class="letra-color-lumen letra-robot align-middle">Asistencia</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/reportes']">
        <img src="/assets/images/reporte.png" class="w-25 align-middle" />
        <p class="letra-color-lumen letra-robot align-middle">Reportes</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/configuracion']">
        <img src="/assets/images/configuracion.png" class="w-25 align-middle" />
        <p class="letra-color-lumen letra-robot align-middle">Configuración</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" [routerLink]="['/usuarios']">
        <img src="/assets/images/usuarios.png" class="w-25 align-middle" />
        <p class="letra-color-lumen letra-robot align-middle">Usuarios</p>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="btn btn-icon" (click)="ir('salir')">
          <img src="/assets/images/salir.png" class="w-25 align-middle" />
          <p class="letra-color-lumen letra-robot align-middle">Salir</p>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!esPantallaPequena">
  <div class="titulo-sistema letra-color-lumen letra-robot" *ngIf="!estado">
      <h2>Liceo Lumen</h2>
      <h1>Sistema de Información Académico</h1>
  </div>
  <div class="menu">
      <div class="btn trigger">
        <!--<span class="line"></span>-->
        <img src="/assets/images/logo.png" [class]="estado ? 'logo-encendido' : 'logo-apagado'" />
      </div>
      <div class="icons">
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/docentes']">
            <img src="/assets/images/docentes.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/estudiantes']">
              <img src="/assets/images/estudiantes.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/sprints']">
              <img src="/assets/images/actividades.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/asistencia']">
              <img src="/assets/images/registro.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" (click)="ir('salir')">
              <img src="/assets/images/salir.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/reportes']">
              <img src="/assets/images/reporte.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/configuracion']">
              <img src="/assets/images/configuracion.png" class="item-menu" />
          </div>
        </div>
        <div class="rotater">
          <div class="btn btn-icon" [routerLink]="['/usuarios']">
              <img src="/assets/images/usuarios.png" class="item-menu" />
          </div>
        </div>
      </div>
    </div>
</ng-container>