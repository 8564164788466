import { AfterViewInit, Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements AfterViewInit {

  esPantallaPequena: boolean = false;

  public estado = false;

  constructor(
    private router: Router, 
    private route: ActivatedRoute
  ) {
    this.esPantallaPequena = window.innerWidth <= 768;
  }
  
  ngAfterViewInit(): void {
    this.estado = false;
    const triggerElement = document.querySelector('.trigger');
    const menuElement = document.querySelector('.menu');

    if (triggerElement && menuElement) {
      triggerElement.addEventListener('click', () => {
        menuElement.classList.toggle('active');
        this.estado = !this.estado;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.esPantallaPequena = window.innerWidth <= 768; // Puedes ajustar el valor según tus necesidades
  }

  ir(opcion:any) {
    let titulo = "";
    let path = "";
    switch(opcion) {
      case 'docentes':
        titulo = "Ingreso a gestión de docentes";
        path = "docentes";
        break;
      case 'estudiantes':
        titulo = "Ingreso a gestión de estudiantes";
        path = "estudiantes";
        break;
      case 'actividades':
        titulo = "Ingreso a gestión de actividades";
        path = "actividades";
        break;
      case 'registro':
        titulo = "Ingreso a seguimiento de actividades";
        path = "registro";
        break;
      case 'salir':
        titulo = "¿Desea salir del sistema?";
        path = "salir";
        break;
      case 'reportes':
        titulo = "Ingreso a consulta de reportes";
        path = "reportes";
        break;
      case 'configuracion':
        titulo = "Ingreso a configuración";
        path = "configuracion";
        break;
      case 'usuarios':
        titulo = "Ingreso a gestión de usuarios";
        path = "usuarios";
        break;
    }
    Swal.fire({
      title: titulo,
      icon: "info",
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "ingresar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.router.navigate([`${path}`]);
      }
    });
  }

}