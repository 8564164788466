import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { RegistroComponent } from './components/registro/registro.component';
import { ActividadesComponent } from './components/actividades/actividades.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { DocentesComponent } from './components/docentes/docentes.component';
import { CrearDocentesComponent } from './components/docentes/crear-docentes/crear-docentes.component';
import { LoginComponent } from './components/login/login.component';
import { CrearActividadesComponent } from './components/actividades/crear-actividades/crear-actividades.component';
import { SprintsComponent } from './components/sprints/sprints.component';
import { CrearSprintsComponent } from './components/sprints/crear-sprints/crear-sprints.component';
import { AsistenciaComponent } from './components/asistencia/asistencia.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'menu', component: MenuComponent},
  { path: 'docentes', component: DocentesComponent},
  { path: 'docentes/:accion/:id', component: CrearDocentesComponent},
  { path: 'estudiantes', component: EstudiantesComponent},
  { path: 'actividades', component: ActividadesComponent},
  { path: 'actividades/:accion/:id', component: CrearActividadesComponent},
  { path: 'registro', component: RegistroComponent},
  { path: 'salir', component: MenuComponent},
  { path: 'reportes', component: ReportesComponent},
  { path: 'configuracion', component: ConfiguracionComponent},
  { path: 'usuarios', component: UsuariosComponent},
  { path: 'sprints', component: SprintsComponent},
  { path: 'sprints/:accion/:id', component: CrearSprintsComponent},
  { path: 'asistencia', component: AsistenciaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
