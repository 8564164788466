import { Component } from '@angular/core';
import { EstudiantesService } from '../../services/estudiantes.service';

@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrl: './estudiantes.component.css'
})
export class EstudiantesComponent {

  titulo = "Módulo de gestión de estudiantes";

  public titulos = [] as any[];

  public datos = [] as any[];

  constructor(
    private estudiantesService: EstudiantesService
  ) {}

  ngOnInit(): void {
    this.crearTitulos();
    this.estudiantesService.obtenerTodosXGrupo(0).subscribe(response => {
      const body = response.body as any[];
      console.log("consumo servicio docentes", body);
      this.datos = body;
    })    
  }

  eliminar(valor:any) {
    console.log("SE VA A ELMINAR EL REGISTRO " + valor);
  }

  crearTitulos() {
    this.titulos = [
      {
        clave: 'id',
        alias: 'id',
        alinear: 'centrado',
      },
      {
        clave: 'anio',
        alias: 'Año',
        alinear: 'centrado',
      },
      {
        clave: 'nombre_grupo',
        alias: 'Grupo',
        alinear: 'izquierda',
      },
      {
        clave: 'primer_nombre',
        alias: 'Primer nombre',
        alinear: 'izquierda',
      },
      {
        clave: 'segundo_nombre',
        alias: 'Segundo nombre',
        alinear: 'izquierda',
      },
      {
        clave: 'primer_apellido',
        alias: 'Primer apellido',
        alinear: 'izquierda',
      },
      {
        clave: 'segundo_apellido',
        alias: 'Segundo apellido',
        alinear: 'izquierda',
      }
    ];
  }

  buscar(event:any) {
    console.log("buscar", event);
  }

}
