import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MenuComponent } from './components/menu/menu.component';
import { RouterModule } from '@angular/router';
import { DocentesComponent } from './components/docentes/docentes.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { ActividadesComponent } from './components/actividades/actividades.component';
import { RegistroComponent } from './components/registro/registro.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { HeaderComponent } from './common/header/header.component';
import { BuscarComponent } from './common/buscar/buscar.component';
import { TablasComponent } from './common/tablas/tablas.component';
import { HttpClientModule } from '@angular/common/http';
import { CrearDocentesComponent } from './components/docentes/crear-docentes/crear-docentes.component';
import { LoginComponent } from './components/login/login.component';
import { CrearActividadesComponent } from './components/actividades/crear-actividades/crear-actividades.component';
import { SprintsComponent } from './components/sprints/sprints.component';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { RadarComponent } from './common/graficas/radar/radar.component';
import { SearchPipeGeneral } from './common/pipes/search';
import { CrearSprintsComponent } from './components/sprints/crear-sprints/crear-sprints.component';
import { CalendarioComponent } from './common/calendario/calendario.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ConstantesService } from './common/constantes/constantes.service';
import { AsistenciaComponent } from './components/asistencia/asistencia.component';
import { MedidasEstudiantesComponent } from './components/reportes/medidas-estudiantes/medidas-estudiantes.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    BuscarComponent,
    DocentesComponent,
    CrearDocentesComponent,
    TablasComponent,
    EstudiantesComponent,
    ActividadesComponent,
    CrearActividadesComponent,
    RegistroComponent,
    ReportesComponent,
    SprintsComponent,
    CrearSprintsComponent,
    ConfiguracionComponent,
    UsuariosComponent,
    LoginComponent,
    RadarComponent,
    SearchPipeGeneral,
    CalendarioComponent,
    AsistenciaComponent,
    MedidasEstudiantesComponent
  ],
  exports: [
    SearchPipeGeneral
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    NgChartsModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    { provide: NgChartsConfiguration, useValue: { generateColors: false }},
    ConstantesService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private constantesService: ConstantesService) {
    // Cargar los datos una vez en la inicialización del módulo
    this.constantesService.cargarListas();
  }
}
