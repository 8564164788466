<div class="franja-titulo">
  <div class="seccion-texto-titulo">
    <img src="/assets/images/docentes.png" class="icono-titulo" />
    <h2 class="letra-robot letra-color-negro texto-titulo">{{ titulo }}</h2>
    <img src="/assets/images/crear.png" class="icono-titulo" [routerLink]="[path]" *ngIf="iconoCrear" />
  </div>
  <div class="seccion-icono-regresar">
    <img
      src="/assets/images/regresar.png"
      class="icono-regresar"
      [routerLink]="[pathRegresar]"
    />
  </div>
</div>
