import { Component } from '@angular/core';

@Component({
  selector: 'app-crear-actividades',
  templateUrl: './crear-actividades.component.html',
  styleUrl: './crear-actividades.component.scss'
})
export class CrearActividadesComponent {

  public titulo = "docente";
  public id = "0";
  public accion = "";
  public editable = false;
  public nuevo = false;

  public listas = {
    
  }

}
