import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TiposIdentificacionService } from '../../../services/tipos-identificacion.service';
import { GenerosService } from '../../../services/generos.service';
import { PersonasService } from '../../../services/personas.service';
import { DocentesService } from '../../../services/docentes.service';

@Component({
  selector: 'app-crear-docentes',
  templateUrl: './crear-docentes.component.html',
  styleUrl: './crear-docentes.component.scss'
})
export class CrearDocentesComponent implements OnInit {

  public titulo = "docente";
  public id = "0";
  public accion = "";
  public editable = false;
  public nuevo = false;

  public listas = {
    tiposIdentificacion: [] as any[],
    generos: [] as any[],
  }

  public model = {
    idPersona: 0 as any,
    tipoIdentificacion: 0 as any,
    numeroIdentificacion: 0 as any,
    primerNombre: "" as any,
    segundoNombre: "" as any,
    primerApellido: "" as any,
    segundoApellido: "" as any,
    fechaNacimiento: "" as any,
    genero: 0 as any,
    direccion: "" as any,
    correoElectronico: "" as any
  }

  constructor(
    private route: ActivatedRoute,
    private tiposIdentificacionService: TiposIdentificacionService,
    private generosService: GenerosService,
    private personasService: PersonasService,
    private docentesService: DocentesService
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe(params => {
        // console.log("query params",params['id'],params['accion']);
        this.accion = params['accion'];
        this.id = params['id'];
        switch(this.accion) {
          case 'crear':
            this.editable = true;
            this.nuevo = true;
            this.consultarListas();
            break;
          case 'editar':
            this.editable = true;
            this.consultarListas();
            break;
        }
      }
    );
  }

  consultarListas() {
    this.tiposIdentificacionService.obtenerTodos().subscribe((response:any)=>{
      console.log(response);
      this.listas.tiposIdentificacion = response.body;
    });
    this.generosService.obtenerTodos().subscribe((response:any)=>{
      console.log(response);
      this.listas.generos = response.body;
    });
  }

  consultaPersona() {
    if(this.editable) {
      this.personasService.obtenerByIdentificacion(this.model.tipoIdentificacion, this.model.numeroIdentificacion).subscribe((response:any)=>{
        console.log(response);
        const datos = response.body;
        if(datos.length > 0) {
          this.model.idPersona = datos[0].id;
          this.model.primerNombre = datos[0].primer_nombre;
          this.model.segundoNombre = datos[0].segundo_nombre;
          this.model.primerApellido = datos[0].primer_apellido;
          this.model.segundoApellido = datos[0].segundo_apellido;
          this.model.fechaNacimiento = datos[0].fecha_nacimiento;
          this.model.genero = datos[0].id_genero;
          this.model.direccion = datos[0].direccion;
          this.model.correoElectronico = datos[0].correo_electronico;
          this.consultarDocenteByIdPersona();
        }
      });
    }
  }

  consultarDocenteByIdPersona() {
    this.docentesService.obtenerByIdPersona(this.model.idPersona).subscribe((response:any)=>{
      console.log("Docente por persona", response);
    });
  }
}
