import { Component } from '@angular/core';
import { UsuariosService } from '../../services/usuarios.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  public usuario = null as any;
  public password = "";
  public listaUsuarios = [] as any[];

  constructor(
    private usuariosService:UsuariosService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.obtenerListaUsuarios();
  }

  presionaTecla(valor:any) {
    this.password = this.password + valor;
  }


  obtenerListaUsuarios() {
    this.usuariosService.obtenerTodos().subscribe((response:any) => {
      console.log(response);
      this.listaUsuarios = response.body;
      console.log(this.listaUsuarios);
    })
  }

  ingresar() {
    const elemento = {
      usuario: this.usuario,
      clave: this.password
    };

    console.log("Ingreso", elemento);
    this.usuariosService.autenticacion(elemento).subscribe((response:any) => {
      console.log("ingreso response", response);
      const data = response[0];
      if(data) {
        console.log("Ingreso satisfactorio");
        localStorage.setItem('usuario', data);
        this.router.navigate(['/menu']);
      } else {
        console.log("Error en autenticación.");
      }
    })
  }

  borrar() {
    console.log("borrar");
    this.password = "";
    this.usuario = null;
  }
}
