import { Component } from '@angular/core';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrl: './reportes.component.css'
})
export class ReportesComponent {

  titulo = "Módulo de registro de asistencia";

  public titulos = [] as any[];

  public datos = [] as any[];

  public listas = {
    noIngresos: [] as any[],
    noSalidas: [] as any[],
  };

  public model = {
    estudiante: {} as any,
    opcion: ""
  };

  seleccionarOpcion(opcion:any) {
    if(opcion != this.model.opcion) {
      this.model.opcion = opcion;
    }
  }
}
