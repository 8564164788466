import { Component, OnInit } from '@angular/core';
import { SprintsService } from '../../services/sprints.service';

@Component({
  selector: 'app-sprints',
  templateUrl: './sprints.component.html',
  styleUrl: './sprints.component.scss'
})
export class SprintsComponent implements OnInit {
  
  public titulo = "Módulo de gestión de sprints";
  public id = "0";
  public accion = "";
  public editable = false;
  public nuevo = false;

  public titulos = [
    {
      clave: 'id',
      alias: 'id',
      alinear: 'centrado',
    },
    {
      clave: 'anio',
      alias: 'año',
      alinear: 'centrado',
    },
    {
      clave: 'nombre_corte_academico',
      alias: 'corte académico',
      alinear: 'izquierda',
    },
    {
      clave: 'numero_sprint',
      alias: 'número sprint',
      alinear: 'centrado',
    },
    {
      clave: 'fecha_inicial',
      alias: 'inicio',
      alinear: 'centrado',
    },
    {
      clave: 'fecha_final',
      alias: 'final',
      alinear: 'centrado',
    },
  ] as any[];

  public datos = [] as any[];

  public listas = {
    
  }

  constructor(
    private sprintsService: SprintsService
  ) {}

  ngOnInit(): void {
    this.sprintsService.obtenerTodos().subscribe(response => {
      const body = response.body as any[];
      console.log("consumo servicio sprints", body);
      this.datos = body;
    })    
  }

  buscar(event:any) {
    console.log("buscar", event);
  }

  eliminar(valor:any) {
    console.log("SE VA A ELMINAR EL REGISTRO " + valor);
  }

}
