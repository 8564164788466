import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnChanges {

  @Input() tituloModulo:any;
  @Input() accion:any;
  @Input() id:any;
  @Input() raiz:any;
  @Input() regresar:any;
  @Input() mostrarCrear:any;
  
  titulo = "";
  idRegistro = "0";
  path = "";
  pathRegresar = "";
  iconoCrear = false;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes["tituloModulo"]) {
      this.titulo = changes["tituloModulo"]["currentValue"];
    }

    if (changes["mostrarCrear"]) {
      this.iconoCrear = changes["mostrarCrear"]["currentValue"];
    }

    if (changes["id"]) {
      this.idRegistro = changes["id"]["currentValue"];
    }
    
    if (changes["raiz"]) {
      this.path = this.iconoCrear ? changes["raiz"]["currentValue"]+"/crear/0" : changes["raiz"]["currentValue"];
    }

    if (changes["accion"]) {
      const _accion = changes["accion"]["currentValue"];
      switch(_accion) {
        case 'crear':
          this.titulo = "Crear " + this.titulo;
          break;
        case 'editar':
          this.titulo = "Editar " + this.titulo + ": " + this.idRegistro;
          break;
        case 'consultar':
          this.titulo = "Consultar " + this.titulo + ": " + this.idRegistro;
          break;
      }
    }

    if (changes["regresar"]) {
      this.pathRegresar = changes["regresar"]["currentValue"];
    }
  }

}
