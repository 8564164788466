<div class="modulo">
    <app-header [tituloModulo]="titulo" [mostrarCrear]="true" [accion]="'ConsultarTodos'" [raiz]="'/docentes'" [regresar]="'/menu'"></app-header>
    <div class="container-fluid mt-5 px-5">
        <div class="row mt-1 mb-5">
            <div class="col-sm-12 col-md-4 px-3">
                <div [ngClass]="model.opcion == 'ingresos' ? 'text-center ficha-seleccionada' : 'text-center ficha'" (click)="seleccionarOpcion('ingresos')">Ingresos</div>
            </div>
            <div class="col-sm-12 col-md-4 px-3">
                <div [ngClass]="model.opcion == 'salidas' ? 'text-center ficha-seleccionada' : 'text-center ficha'" (click)="seleccionarOpcion('salidas')">Salidas</div>
            </div>
            <div class="col-sm-12 col-md-4 px-3">
                <div class="text-center ficha">Actual: {{ listas.noSalidas.length }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" *ngIf="model.opcion == 'ingresos'">
                <ul class="lista-seleccionable">
                    <ng-container *ngFor="let estudiante of listas.noIngresos">
                        <li class="item-lista" (click)="seleccionarEstudiante(estudiante)">{{ estudiante.primer_nombre }} {{ estudiante.segundo_nombre }} {{ estudiante.primer_apellido }} {{ estudiante.segundo_apellido }}</li>
                    </ng-container>
                </ul>
            </div>
            <div class="col-md-12" *ngIf="model.opcion == 'salidas'">
                <ul class="lista-seleccionable">
                    <ng-container *ngFor="let estudiante of listas.noSalidas">
                        <li class="item-lista" (click)="seleccionarEstudiante(estudiante)" >{{ estudiante.primer_nombre }} {{ estudiante.segundo_nombre }} {{ estudiante.primer_apellido }} {{ estudiante.segundo_apellido }} ({{ estudiante.fecha | date:'shortTime' }})</li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>
  
