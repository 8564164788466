<div class="modulo">
    <app-header [tituloModulo]="titulo" [mostrarCrear]="false" [accion]="accion" [id]="id" [raiz]="'/sprints'" [regresar]="'/sprints'"></app-header>
    <div class="container-fluid mt-5 px-5">
        <div class="row mt-1 mb-5">
            <div class="col-md-6 px-3">
                <div class="text-center ficha-seleccionada">{{ model.corte.nombre }}</div>
            </div>
            <div class="col-md-6 px-3">
                <div class="text-center ficha" (click)="seleccionarAsignatura()">{{ model.asignatura.nombre == null ? 'Seleccionar asignatura' : model.asignatura.nombre }}</div>
            </div>
        </div>
        <div class="row mt-1 mb-5">
            <ng-container *ngFor="let grupo of listas.grupos">
                <div class="col px-3">
                    <div [ngClass]="grupo.id == model.grupo.id ? 'text-center ficha-seleccionada' : 'text-center ficha'" (click)="seleccionarGrupo(grupo)">{{ grupo.nombre }}</div>
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ul class="lista-seleccionable">
                    <ng-container *ngFor="let actividadCorte of listas.actividadesCorte">
                        <li class="item-lista" (click)="seleccionarActividad(actividadCorte)">{{ actividadCorte.nombre }}</li>
                    </ng-container>
                </ul>
            </div>
            <div class="col-md-6">
                <ul class="lista-seleccionable">
                    <ng-container *ngFor="let actividadSprint of listas.actividadesSprint; let i = index">
                        <li class="item-lista" (click)="eliminarActividad(i)">{{ actividadSprint.nombre }}</li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>