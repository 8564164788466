<app-buscar (buscar)="buscar($event)"></app-buscar>
<div class="form-tablas mt-3 mb-5">
    <table>
        <thead>
            <tr>
                <th colspan="3">Acciones</th>
                <ng-container *ngFor="let titulo of tabla.titulos">
                    <th rowspan="2">{{ titulo.alias }}</th>
                </ng-container>
            </tr>
            <tr>
                <th>Ver detalle</th>
                <th>Editar</th>
                <th>Eliminar</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let dato of tabla.datos | SearchPipeGeneral: buscarTexto">
                <tr>
                    <td>
                        <p class="accion">
                            <img src="/assets/images/detalle.png" width="50" [routerLink]="[path+'/consultar/'+dato.id]" />
                        </p>
                    </td>
                    <td>
                        <p class="accion">
                            <img src="/assets/images/editar.png" width="50" [routerLink]="[path+'/editar/'+dato.id]" />
                        </p>
                    </td>
                    <td>
                        <p class="accion">
                            <img src="/assets/images/eliminar.png" width="50" (click)="eliminarRegistro(dato.id)" />
                        </p>
                    </td>
                    <ng-container *ngFor="let titulo of tabla.titulos">
                        <td>
                            <p [class]="titulo.alinear">
                                {{ dato[titulo.clave] }}
                            </p>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>