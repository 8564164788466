import {
    HttpClient,
    HttpErrorResponse,
    HttpResponse,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { environment } from '../../environments/environment';
  import { Observable, throwError } from 'rxjs';
  import { catchError, tap } from 'rxjs/operators';
  import { httpOptions } from './http';
  
  @Injectable({
    providedIn: 'root'
  })
  export class AsistenciaEstudiantesService {
  
    private servicio = environment.api + 'asistencia-estudiantes';
    private servicioIngreso = environment.api + 'asistencia-estudiantes-ingresos';
    private servicioNoIngreso = environment.api + 'asistencia-estudiantes-no-ingresos';
    private servicioSalida = environment.api + 'asistencia-estudiantes-salidas';
    private servicioNoSalida = environment.api + 'asistencia-estudiantes-no-salidas';
  
    constructor(private http: HttpClient) {}
  
    obtenerTodos() {
      return this.http
        .get<HttpResponse<Object>>(this.servicio, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<Object>) => {
            let respuesta: any = response.body;
            if (respuesta.error) {
              throw respuesta.error;
            }
            return response;
          }),
          catchError(this.handleError)
        );
    }

    obtenerNoIngresos() {
      return this.http
        .get<HttpResponse<Object>>(this.servicioNoIngreso, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<Object>) => {
            let respuesta: any = response.body;
            if (respuesta.error) {
              throw respuesta.error;
            }
            return response;
          }),
          catchError(this.handleError)
        );
    }

    obtenerNoSalidas() {
      return this.http
        .get<HttpResponse<Object>>(this.servicioNoSalida, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<Object>) => {
            let respuesta: any = response.body;
            if (respuesta.error) {
              throw respuesta.error;
            }
            return response;
          }),
          catchError(this.handleError)
        );
    }

    registroIngreso(id:any, observacion:any) {
        const body = JSON.stringify({id_estudiante: id, accion: 'ingreso', observacion: observacion });
        
        return this.http.post<any>(this.servicio, body, httpOptions).pipe(
          tap((respuesta: any) => {
            //Se valida que si existe un mensaje de error
            if (respuesta.error) {
              console.log(respuesta);
              throw respuesta.error;
            }
            console.log(respuesta);
            return respuesta;
          }),
          catchError(this.handleError)
        );
    }

    registroSalida(id:any, observacion:any) {
        const body = JSON.stringify({id_estudiante: id, accion: 'salida', observacion: observacion });
        
        return this.http.post<any>(this.servicio, body, httpOptions).pipe(
          tap((respuesta: any) => {
            //Se valida que si existe un mensaje de error
            if (respuesta.error) {
              console.log(respuesta);
              throw respuesta.error;
            }
            console.log(respuesta);
            return respuesta;
          }),
          catchError(this.handleError)
        );
    }
  
    private handleError(error: HttpErrorResponse) {
      return throwError(() => error);
    }
  }
  