<div class="modulo">
    <app-header [tituloModulo]="titulo" [mostrarCrear]="true" [accion]="'ConsultarTodos'" [raiz]="'/docentes'" [regresar]="'/menu'"></app-header>
    <div class="container-fluid mt-5 px-5">
        <div class="row mt-1 mb-5">
            <div class="col-sm-12 col-md-4 px-3">
                <div [ngClass]="model.opcion == 'medidas' ? 'text-center ficha-seleccionada' : 'text-center ficha'" (click)="seleccionarOpcion('medidas')">Medidas</div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-12" *ngIf="model.opcion == 'medidas'">
                <app-medidas-estudiantes></app-medidas-estudiantes>
            </div>
            
        </div>
    </div>
</div>
  
