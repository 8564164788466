import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GruposService } from '../../../services/grupos.service';
import Swal from 'sweetalert2';
import { AreasAcademicasService } from '../../../services/areas-academicas.service';

@Component({
  selector: 'app-crear-sprints',
  templateUrl: './crear-sprints.component.html',
  styleUrl: './crear-sprints.component.scss',
})
export class CrearSprintsComponent {
  public titulo = 'sprint';
  public id = '0';
  public accion = '';
  public editable = false;
  public nuevo = false;

  public listas = {
    grupos: [] as any[],
    areasAcademicas: [] as any[],
    actividadesCorte: [
      {
        id: 1,
        nombre: 'act 1',
        descripcion: 'asdasddas',
      },
      {
        id: 2,
        nombre: 'act 2',
        descripcion: 'asdasddas',
      },
      {
        id: 3,
        nombre: 'act 3',
        descripcion: 'asdasddas',
      },
      {
        id: 4,
        nombre: 'act 4',
        descripcion: 'asdasddas',
      },
    ] as any[],
    actividadesSprint: [
      {
        id: 1,
        nombre: 'act 1',
        descripcion: 'asdasddas',
      },
    ] as any[],
  };

  public model = {
    grupo: {} as any,
    corte: {
      nombre: 'demo',
    } as any,
    asignatura: {} as any
  };

  constructor(
    private route: ActivatedRoute,
    private gruposService: GruposService,
    private areasAcademicasService: AreasAcademicasService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // console.log("query params",params['id'],params['accion']);
      this.accion = params['accion'];
      this.id = params['id'];
      switch (this.accion) {
        case 'crear':
          this.editable = true;
          this.nuevo = true;
          this.consultarListas();
          break;
        case 'editar':
          this.editable = true;
          this.consultarListas();
          break;
      }
    });
  }

  consultarListas() {
    this.gruposService.obtenerTodos().subscribe((response: any) => {
      console.log(response);
      this.listas.grupos = response.body;
    });
    this.areasAcademicasService.obtenerTodos().subscribe((response: any) => {
      console.log(response);
      this.listas.areasAcademicas = response.body;
    });
  }

  seleccionarGrupo(grupo: any) {
    this.model.grupo = grupo;
    console.log(grupo, this.model.grupo);
  }
  seleccionarAsignatura() {
    console.log("areas academicas", this.listas.areasAcademicas);
    const lista = {} as any;
    this.listas.areasAcademicas.forEach((aa:any, index)=>{
      lista[index] = aa.nombre;
    });

    Swal.fire({
      title: "Seleccionar área académica",
      input: "select",
      inputOptions: lista,
      /*inputOptions: {
        apples: "Apples",
        bananas: "Bananas",
        grapes: "Grapes",
        oranges: "Oranges"
      },*/
      inputPlaceholder: "Seleccionar...",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log(result);
        this.model.asignatura = this.listas.areasAcademicas[+result.value];
        // this.listas.actividadesSprint.push(actividad);
      }
    });
  }

  seleccionarActividad(actividad:any) {
    Swal.fire({
      title: actividad.nombre,
      text: actividad.descripcion,
      icon: "question",
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "agregar al sprint",
      cancelButtonText: "cancelar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.listas.actividadesSprint.push(actividad);
      }
    });
  }

  eliminarActividad(actividad:any) {
    Swal.fire({
      title: actividad.nombre,
      text: actividad.descripcion,
      icon: "question",
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "eliminar del sprint",
      cancelButtonText: "cancelar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.listas.actividadesSprint.splice(actividad, 1);
        // this.listas.actividadesSprint.push(actividad);
      }
    });
  }
}
