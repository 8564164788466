import { Component, OnInit } from '@angular/core';
import { DocentesService } from '../../services/docentes.service';

@Component({
  selector: 'app-docentes',
  templateUrl: './docentes.component.html',
  styleUrl: './docentes.component.scss'
})
export class DocentesComponent implements OnInit {

  titulo = "Módulo de gestión de docentes";

  public titulos = [] as any[];

  public datos = [] as any[];

  constructor(
    private docentesService: DocentesService
  ) {}

  ngOnInit(): void {
    this.crearTitulos();
    this.docentesService.obtenerTodos().subscribe(response => {
      const body = response.body as any[];
      console.log("consumo servicio docentes", body);
      this.datos = body;
    })    
  }

  eliminar(valor:any) {
    console.log("SE VA A ELMINAR EL REGISTRO " + valor);
  }

  crearTitulos() {
    this.titulos = [
      {
        clave: 'id',
        alias: 'id',
        alinear: 'centrado',
      },
      /*{
        clave: 'id_persona',
        alinear: 'centrado',
      },*/
      /*{
        clave: 'id_nivel_escolaridad',
        alinear: 'izquierda',
      },*/
      {
        clave: 'nivel_escolaridad',
        alias: 'Nivel de escolaridad',
        alinear: 'derecha',
      },
      {
        clave: 'primer_nombre',
        alias: 'Primer nombre',
        alinear: 'derecha',
      },
      {
        clave: 'segundo_nombre',
        alias: 'Segundo nombre',
        alinear: 'derecha',
      },
      {
        clave: 'primer_apellido',
        alias: 'Primer apellido',
        alinear: 'derecha',
      },
      {
        clave: 'segundo_apellido',
        alias: 'Segundo apellido',
        alinear: 'derecha',
      },
      /*{
        clave: 'id_tipo_identificacion',
        alinear: 'derecha',
      },*/
      {
        clave: 'tipo_identificacion',
        alias: 'Tipo de identificación',
        alinear: 'derecha',
      },
      {
        clave: 'numero_identificacion',
        alias: 'Número de identificación',
        alinear: 'derecha',
      },
      {
        clave: 'fecha_nacimiento',
        alias: 'Fecha de nacimiento',
        alinear: 'derecha',
      },
      /*{
        clave: 'id_genero',
        alinear: 'derecha',
      },*/
      {
        clave: 'nombre_genero',
        alias: 'Género',
        alinear: 'derecha',
      },
      {
        clave: 'direccion',
        alias: 'Dirección',
        alinear: 'derecha',
      },
      {
        clave: 'activo',
        alias: 'Activo',
        alinear: 'centrado',
      },
    ];
  }

  buscar(event:any) {
    console.log("buscar", event);
  }

}
