<div class="modulo">
  <app-header
    [tituloModulo]="titulo"
    [mostrarCrear]="true"
    [accion]="'ConsultarTodos'"
    [raiz]="'/actividades'"
    [regresar]="'/menu'"
  ></app-header>
  <app-buscar (buscar)="buscar($event)"></app-buscar>
  <app-tablas
    [titulos]="titulos"
    [datos]="datos"
    [raiz]="'/actividades'"
    (eliminar)="eliminar($event)"
  ></app-tablas>
</div>
