import { Component } from '@angular/core';
import { EstudiantesService } from '../../services/estudiantes.service';
import { AsistenciaEstudiantesService } from '../../services/asistencia-estudiantes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrl: './asistencia.component.scss'
})
export class AsistenciaComponent {

  titulo = "Módulo de registro de asistencia";

  public titulos = [] as any[];

  public datos = [] as any[];

  public listas = {
    noIngresos: [] as any[],
    noSalidas: [] as any[],
  };

  public model = {
    estudiante: {} as any,
    opcion: ""
  };

  constructor(
    private asistenciaEstudiantesService: AsistenciaEstudiantesService
  ) {}

  ngOnInit(): void {
    this.consultaNoSalidas();
  }

  consultaNoIngresos() {
    this.asistenciaEstudiantesService.obtenerNoIngresos().subscribe(response => {
      const body = response.body as any[];
      console.log("consumo servicio docentes", body);
      this.listas.noIngresos = body;
    });
  }

  consultaNoSalidas() {
    this.asistenciaEstudiantesService.obtenerNoSalidas().subscribe(response => {
      const body = response.body as any[];
      console.log("consumo servicio docentes", body);
      this.listas.noSalidas = body;
    });
  }

  buscar(event:any) {
    console.log("buscar", event);
  }

  seleccionarOpcion(opcion:any) {
    if(opcion != this.model.opcion) {
      this.model.opcion = opcion;
      switch(opcion) {
        case "ingresos":
          this.consultaNoIngresos();
          break;
        case "salidas":
          this.consultaNoSalidas();
          break;
      }
    }
  }

  seleccionarEstudiante(estudiante:any) {
    Swal.fire({
      title: 'Observaciones',
      input: "text",
      icon: "question",
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "registrar",
      cancelButtonText: "cancelar"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        switch(this.model.opcion) {
          case "ingresos":
            this.asistenciaEstudiantesService.registroIngreso(estudiante.id, result.value).subscribe((response:any)=>{
              if(response) {
                this.consultaNoIngresos();
                this.consultaNoSalidas();
              }
            });
            break;
          case "salidas":
            console.log("se registra salida de ", estudiante);
            this.asistenciaEstudiantesService.registroSalida(estudiante.id_estudiante, result.value).subscribe((response:any)=>{
              console.log("respuesta", response);
              if(response) {
                this.consultaNoSalidas();
              }
            });
            break;  
        }
      }
    });
  }

}
