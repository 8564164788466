import { Component, OnInit } from '@angular/core';
import { EstudiantesService } from '../../../services/estudiantes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medidas-estudiantes',
  templateUrl: './medidas-estudiantes.component.html',
  styleUrl: './medidas-estudiantes.component.scss'
})
export class MedidasEstudiantesComponent implements OnInit {

  public listas = {
    estudiantes: [] as any[]
  };

  public model = {
    estudiante: {} as any
  };

  constructor(
    private estudiantesService: EstudiantesService
  ) { }

  ngOnInit() {
    this.estudiantesService.obtenerTodos().subscribe((response:any)=>{
      console.log("response estudiantes", response);
      this.listas.estudiantes = response.body;
    });
  }

  seleccionarEstudiante(estudiante:any) {
    // to-do
  }

}
